import * as React from 'react'
import Helmet from 'react-helmet'
import TopBar from '../components/top-bar'
import Header from './header'
import Footer from '../components/footer'

const Layout = ({ pageTitle, children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <TopBar />
      <Header />
      <main className="flex-1 2xl:container">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
