import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SectionLayout from '../components/section-layout'

const LifestylePage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(filter: { frontmatter: { tags: { eq: "lifestyle" } } }) {
        edges {
          node {
            frontmatter {
              title
              slug
              sidebar
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    src
                  }
                }
              }
            }
          }
        }
      }
      hero: file(relativePath: { eq: "lifestyle-page.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `)

  return <SectionLayout pageTitle="Lifestyle" data={data} />
}

export default LifestylePage
