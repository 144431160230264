import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'

const SectionLayout = ({ pageTitle, data }) => {
  const image = getImage(data.hero.childImageSharp.gatsbyImageData)
  return (
    <Layout pageTitle={pageTitle}>
      <div className="relative">
        <GatsbyImage
          image={image}
          className="object-cover w-full h-full"
          alt="Image of silhouette of an Elk"
        />
        <div className="absolute inset-0 bg-gray-900 bg-opacity-75">
          <div className="px-4 py-20 sm:py-40 lg:py-60 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <h1 className="text-center font-sans text-3xl md:text-6xl lg:text-8xl font-bold text-white tracking-tight sm:leading-none">
              {pageTitle}
            </h1>
          </div>
        </div>
      </div>
      <section className="px-4 py-8 lg:px-16 md:py-16 lg:py-32 bg-topo-map">
        <div className="container grid sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-12">
          {data.allMdx.edges.map(({ node }, i) => (
            <Link to={node.frontmatter.slug} key={i}>
              <div>
                <img
                  src={node.frontmatter.featuredImage.childImageSharp.fluid.src}
                  className="rounded-md drop-shadow-lg"
                  alt={node.frontmatter.title}
                />
                <h3 className="text-2xl font-extrabold tracking-tight text-gray-900">
                  {node.frontmatter.title}
                </h3>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default SectionLayout
